// Vendor
import '@/helpers/bugsnag';

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/rias/ls.rias';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'simplebar'; // @TODO import only where it needed

// Core
import '@/scripts/core/a11y';

// Components
import '@/scripts/components/utm'; // @TODO do we need it?
import '@/scripts/components/drawers'; // @TODO import only where needed
import '@/scripts/components/cart'; // @TODO check what we can do with [data-product-submit] property
import '@/scripts/components/quick-view'; // @TODO try to rewrite to new sections code-base
import '@/scripts/components/zoom'; // @TODO import only on PDP if it is required here

// Snippets
import '@/helpers/video';
import '@/helpers/klaviyo';
import '@/scripts/snippets/modal'; // @TODO check impact of changes if we remove it from here
import '@/scripts/snippets/custom-dropdown'; // @TODO import only it is needed
import '@/scripts/components/modal-product-details';
import '@/scripts/components/recommended-badge';
import '@/scripts/apps/rebuy';

import sections from '@packages/sections/theme-sections';

(async () => {
    const { default: lozad } = await import('lozad');

    if (!lozad) {
        return;
    }

    const imageObserver = lozad('img.lozad', {
        rootMargin: '10px 0px',
        threshold: 0.1,
        enableAutoReload: true,
        loaded(image) {
            image?.classList?.add('image--loaded');
        },
    });

    imageObserver?.observe();

    const imageMutationObserver = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
            if (mutation?.type !== 'childList') {
                return;
            }

            const addedNodes = mutation?.addedNodes;
            if (!addedNodes) {
                return;
            }

            if (!addedNodes?.length) {
                return;
            }

            addedNodes?.forEach((node) => {
                if (node?.nodeType !== 1) {
                    return;
                }

                if (!!node?.matches('.lozad')) {
                    // New `.lozad` element added, observe it with Lozad
                    imageObserver?.triggerLoad(node);

                    imageObserver.observe(node);

                    return;
                }

                const images = Array.from(node?.querySelectorAll('.lozad'));
                if (!images) {
                    return;
                }

                if (!images?.length) {
                    return;
                }

                images?.forEach((image) => {
                    imageObserver?.triggerLoad(image);

                    imageObserver.observe(image);
                });
            });
        }
    });

    imageMutationObserver?.observe(document.body, {
        childList: true,
        subtree: true,
    });
})();

document.addEventListener('DOMContentLoaded', async () => {
    await sections.asyncLoadOnUserInteraction();
});

document.addEventListener('DOMContentLoaded', () => {
    const triggers = document.querySelectorAll('[data-open-search]');
    if (!triggers) {
        return;
    }

    if (!triggers?.length) {
        return;
    }

    triggers?.forEach((trigger) => {
        trigger?.addEventListener('click', (event) => {
            event?.preventDefault();
            event?.stopPropagation();
            event?.stopImmediatePropagation();

            document.dispatchEvent(new CustomEvent('open:search'));
        });
    });
});
